<template>
  <AppLayout>
    <div class="xtx-goods-page">
      <div class="container">
        <Breadcrumb style="margin-bottom: 20px">
          <BreadcrumbItem to="/">首页</BreadcrumbItem>
          <BreadcrumbItem>消息中心</BreadcrumbItem>
        </Breadcrumb>
        <span class="container-header">公告中心</span>
        <List item-layout="vertical" style="margin-top: 20px">
          <ListItem v-for="item in noticeList" :key="item.title">
					<a>
						 <ListItemMeta
              :title="item.title"
              :description="item.title"
              @click="toDetail(item.id)"
            />
            <span class="container-time">发布时间：{{ item.createDate }}</span>
					</a>
          </ListItem>
        </List>
        <div style="float: right">
          <Page :total="data.records" @on-change="onChange" />
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import { timeToStampToTime } from '@/utils/dateFormat'
import { ref } from 'vue'
export default {
  name: 'MessageIndex',
  components: {
    AppLayout,
  },
  data () {
    return {
      noticeList: [],
      data: {},
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.pageData(1)
    },
    pageData (num) {
      const postData = ref({
        page: num, // 当前页
        pageRows: 5, // 数据数量
      })
      this.$store
        .dispatch('NoticeList', postData.value)
        .then((response) => {
          this.data = response.result

          this.noticeList = response.result.rows.map((item) => ({
            title: item.title,
            createDate: timeToStampToTime(item.createDate),
            id: item.id,
          }))
        })
        .catch(() => {})
    },
    toDetail (id) {
      this.$router.push({ path: '/message/detail', query: { messageId: id }})
    },
    onChange (val) {
      this.pageData(val)
    },
  },
  setup () {},
}
</script>

<style scoped lang="less">
.container {
  margin-top: 12px;
  min-height: 800px;
  .container-time {
    font-size: 12px;
    color: #999999;
  }
  .container-header {
    font-size: 24px;
    font-weight: 600;
  }
}
</style>
