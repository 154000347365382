export function timestampToTime (timestamp) {
  // 传入的是本地时区的时间字符串 转化为0时区的时间戳 timeStamp = timeStamp - 8 * 60 * 60 * 1000
  const date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}
/**
 * 格式化时间
 *
 * @param {time} 时间
 * @param {cFormat} 格式
 * @return {String} 字符串
 *
 * @example formatTime('2018-1-29', '{y}/{m}/{d} {h}:{i}:{s}') // -> 2018/01/29 00:00:00
 */
export function formatTime (time, cFormat) {
  if (arguments.length === 0) return null
  if ((time + '').length === 10) {
    time = +time * 1000
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a')
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

export function timeToStamp (timestamp) {
  const date = new Date(timestamp)
  return date.getTime()
}

export function timeToStampToTime (timestamp) {
  const date = new Date(timestamp)

  // const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  return M + D
}

export function timeStrLong (timeStr) {
  const tStr = formatTime(new Date(), '{yyyy}-{mm}-{dd}')
  const chkTime = []
  for (const e in timeStr) {
    const newTime = Date.parse(tStr + ' ' + timeStr[e])
    if (
      timeStr[e] !== null &&
      timeStr[e] !== '' &&
      chkTime.indexOf(newTime) === -1
    ) {
      chkTime.push(newTime)
    }
  }
  return chkTime
}

export function timeToStampHms (timestamp) {
  const date = new Date(timestamp)
  const h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return h + m + s
}

/**
 * 获取当日 0-23间 任意时刻的时间戳
 *
 * @param {timeing} 时间
 * @return {Long} 任意时刻的时间戳

 */
export function getTimeByhhmm (hhmm) {
  const today = formatTime(new Date(), '{yyyy}-{mm}-{dd}')
  return Date.parse(today + ' ' + hhmm)
}
